<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid" /> {{ caption }}
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        striped
        :items="advices"
        :fields="fields"
        :items-per-page="5"
        :sorter="{ resetable: true }"
        :items-per-page-select="{ label: 'Consejos por página:',
                                  values: [
                                    5,
                                    10,
                                    25,
                                    50,
                                    100,
                                    250,
                                    500
                                  ]
        }"
        :table-filter="{label: 'Filtro:', placeholder: 'Comience a escribir...'}"
        :no-items-view="{noResults: 'No se han encontrado datos', noItems: 'No se han encontrado datos que cumplan las características indicadas'}"
        pagination
      >
        <template #titulo="{ item }">
          <td>
            <a
              class="text-info"
              @click="adviceInfo(item.acciones)"
            >{{
              item.titulo
            }}</a>
          </td>
        </template>

        <template #acciones="{ item }">
          <td>
            <CButton
              color="danger"
              shape="pill"
              size="sm"
              @click="deleteButton(item)"
            >
              Eliminar
            </CButton>
            <CModal
              id="modalElim"
              title="Eliminar consejo"
              color="principal"
              :show.sync="deleteModal"
            >
              <p class="text-muted">
                ¿Está seguro de que desea eliminar el consejo titulado: 
                "{{ advice.advice_title }}"? Esta acción no se puede deshacer.
              </p>
              <div slot="footer">
                <CButton
                  color="primary"
                  @click="deleteAdvice(advice.advice_id)"
                  style="margin-right:1em"
                >
                  Aceptar
                </CButton>
                <CButton
                  color="danger"
                  @click="deleteModal = false"
                >
                  Volver
                </CButton>
              </div>
            </CModal>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import config from "@/config";
import {getAdvicesData, checkUser} from '../utils/utils';
export default {
  name: "AdvicesTable",
  props: {
    fields: {
      type: Array,
      default() {
        return [
          {
            key: "advice_title",
            label: "Título del consejo"
          },
          {
            key: "advice_description",
            label: "Descripción de la patología"
          },
          {
            key: "acciones",
            sorter: false,
            filter: false
          }];
      },
    },
    caption: {
      type: String,
      default: "Table",
    },
  },
  data() {
    return {
      deleteModal: false,
      advice: {},
      advices: [],
    };
  },
  created() {
    if (!checkUser()) return;
    this.receiveData();
  },
  methods: {
    receiveData() {
      this.getAdvices();
    },
    /**
     * Función en la que se obtienen las consejos y que también
     * llama el metodo receiveData para cargarlos.
     * 
     * @returns {object[]} Las consejos obtenidas.
     */
    getAdvices() {
      let adviceData = getAdvicesData();
      adviceData.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        let element = {};
        let data = [];
        result.advices.forEach(advice => {
          element = {};
          element.advice_id = advice.advice_id;
          element.advice_title = advice.advice_title;
          element.advice_description = advice.advice_description;
          data.push(element);
        });
        this.advices = data;     
      });
    },
    /**
     * Este es el evento que se ejecuta al hacer click en el boton de eliminar de la tabla acciones
     * Eso hace que se abra el modal
     * 
     * @param {object} advice - El consejo sobre el que se ha llamado al evento
     */
    deleteButton(advice) {
      this.advice = advice;
      this.deleteModal = true;
    },
    /**
     * Este metodo eliminará el consejo, mandando por la URL el id
     * 
     * @param {number} id - El id del consejo a eliminar
     */
    deleteAdvice(id) {
      const token = localStorage.token;
      axios.delete(config.BACK_IP + "/advice/" + id,{headers: {token: token}})
      .then(() => {
        this.getAdvices();
      })
      .catch((error) => {
        if(error.response) {
          if(error.response.status === 401) {
            localStorage.removeItem("token");
            window.location = "#/pages/login";
          }
          if (error.response.status == 404) {
            window.location = "#/pages/404";
          }
          if (error.response.status == 500) {
            window.location = "#/500";
          }
        }
      });
      this.deleteModal = false;
    },
    /**
     * Este metodo, que se ejecuta al hacer click en el nombre del consejo, nos llevará a la página para ver la información del consejo
     * 
     * @param {number} id - El Id del consejo sobre el que se llama el evento
     */
    adviceInfo(id) {
      this.$router.push("/advice/info/" + id);
    },
  },
};
</script>