<template>
  <div>
    <CRow>
      <CCol
        lg="12"
        sm="1"
      >
        <CTableWrapper>
          <template #header>
            <div class="d-flex justify-content-between">
              Consejos
              <CButton
                class="btn btn-add"
                size="sm"
                @click="addAdvice()"
              >
                Añadir consejo
              </CButton>
            </div>
          </template>
        </CTableWrapper>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CTableWrapper from "../../components/AdvicesTable";
import {checkUser} from '../../utils/utils';
import "core-js/stable";
import "regenerator-runtime/runtime";
export default {
  name: "adviceTable",
  components: { CTableWrapper },
  created() {
    if (!checkUser()) return;
  },
  methods: {
    addAdvice(){
      this.$router.push('/advice/create/');
    },
  },
};
</script>